/**
 * Scroll to
 * Scrolls to an element or a certain vertical point.
 * This will take into account the users motions preferences, smooth scroll will only be used if enabled.
 *
 * Instance options:
 *
 * attrName - string - Default: 'data-scroll-to
 * The data attribute name which is added to the element which instigates the scroll to event
 *
 * Parameter options can be passed to the scroll:
 *
 * y - int|string - Default: 0
 * This controls the y scroll position. This can either be a value or an element in the DOM.
 * When referencing an element add it in as a string. If using a class name it will find the first reference
 *
 * offset - int - Default: 0
 * The offset value to be added to the y value
 *
 * Usage:
 *
 * <button data-scroll-to="0">Top</button>
 * Clicking this button scroll to the top of the page
 *
 * <button data-scroll-to="#content,100">To Element</button>
 * Clicking this button scroll to the element with the #content ID with an offset of 100px
 */

class ScrollTo {
    constructor (
        attrName = 'data-scroll-to',
    ) {
        Array.from(
			document.querySelectorAll('[' + attrName + ']') || []
		).forEach((element) =>
			element.addEventListener('click', (e) => {
				e.preventDefault();
				this.scrollTo(e.currentTarget.getAttribute(attrName));
			})
		);
    }
    scrollTo(attrs) {
        const attribute = attrs.split(',');

		let y = attribute[0].trim();
		let offset = attribute[1] ? attribute[1].trim() : 0;

		if (!this._isNum(y)) {
			let targetElement;

			if (typeof y === 'string') {
				targetElement = document.querySelector(y);

                // If no element jump out
                if (!targetElement) {
                    console.error('No element found on the page');
                    return;
                }
			}
            else {
				targetElement = y;
			}

			y = targetElement.getBoundingClientRect().top + (window.pageYOffset || document.body.scrollTop);
		}

        y = y + offset;

		y = y < 0 ? 0 : y;

        // Scroll to element
        window.scroll({
            top: y
        });
	}
    _isNum(val){
        return !isNaN(val);
    }
}

export default new ScrollTo();
