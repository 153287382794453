/* CSS */
import "@js/parts/css";

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log('HMR');
    });
}

// Alpine JS
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import focus from '@alpinejs/focus';

import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

// Project config
import Config from '@js/config/Config';

// Project utils
import utils from '@js/utils/utils';

// Project helpers
// import VhCalc from '@js/helpers/ViewHeightCalc';
// import HhCalc from '@js/helpers/HeaderHeightCalc';

// Project modules
import Analytics from '@js/modules/Analytics';
import GdprMessage from '@js/modules/GdprMessage';
import Copy from '@js/modules/Copy';
import Modal from '@js/modules/Modal';
import PageScrollingManager from '@js/modules/PageScrollingManager';
//import ScrollProgress from '@js/modules/ScrollProgress';
import ScrollTo from '@js/modules/ScrollTo';

// Alpine
window.Alpine = Alpine;
Alpine.plugin(collapse);
Alpine.plugin(focus);
Alpine.start();

window.EEA =
    window.EEA ||
    new (function EEA() {
        // Config
        this.config = Config;

        // Utilities
        this.utils = {
            utils: utils,
        };

        this.modules = {
            PageScrollingManager: PageScrollingManager,
        };

        // Window Load
        window.addEventListener('load', e => {
            PageScrollingManager.update(true);
        });

        // DOM load
        document.addEventListener('DOMContentLoaded', e => {

            /**
             * Content carousel
             */
            const contentCarouselEls = document.querySelectorAll('.content-carousel');
            contentCarouselEls.forEach((item, i) => {
                new Splide(item, {
                    type: 'loop',
                    arrows: true,
                    gap: '1.5em',
                    padding: '10%',
                    pagination: false,
                    perPage: 4,
                    breakpoints: {
                        768: {
                            gap: '1em',
                            padding: '12%',
                            perPage: 1,
                        },
                        1000: {
                            perPage: 2,
                        },
                        1200: {
                            perPage: 3,
                        },
                        1500: {
                            perPage: 3,
                        },
                    }
                }).mount();
            });

            /**
             * Image sliders
             */
            const imageSliderEls = document.querySelectorAll('.image-slider');
            imageSliderEls.forEach((item, i) => {
                new Splide(item, {
                    type: 'loop',
                    arrows: true,
                    gap: 0,
                    pagination: false,
                    perPage: 1,
                }).mount();
            });

            /**
             * Client marquees
             */
            const clientMarqueeEls = document.querySelectorAll('.client-marquee');
            clientMarqueeEls.forEach((item, i) => {
                new Splide(item, {
                    type: 'loop',
                    drag: 'free',
                    focus: 'center',
                    arrows: false,
                    gap: '1.5em',
                    pagination: false,
                    pauseOnHover: false,
                    pauseOnFocus: false,
                    perPage: 6,
                    autoScroll: {
                        speed: 0.9,
                    },
                    breakpoints: {
                        768: {
                            gap: '3em',
                            perPage: 3,
                        },
                        1024: {
                            gap: '2.5em',
                            perPage: 4,
                        },
                        1200: {
                            gap: '1.5em',
                            perPage: 5,
                        },
                    }
                }).mount({ AutoScroll });
            });

        });
    })();
