/**
 * Toast message
 * It works by showing one message at a time, if another message is shown whilst one is already in the DOM then it needs to remove the old one and add the new one.
 *
 * Options:
 *
 * timeout - int - Default: 3000
 * The timeout value in milliseconds, this will control how long the message is on the screen for.
 * Use -1 to constantly show
 *
 * interactive - Boolean - Default: true
 * Whether or not to bind mouseover and mouseleave interactions to the toast message.
 * If this is set to true then on mouseover the timeout should stop, on mouseleave the timeout should restart.
 *
 * appearDelay - int - Default: 0
 * Delay before toast message appears in milliseconds
 *
 * showCloseButton - Boolean - Default: true
 * Whether or not to show the close button, if the close button shows in the toast message and the user clicks on it, it will close the current toast message before the timeout duration has completed.
 * If set to false no button will show and there will be no way to force close the toast message, it will have to wait till the timeout finishes
 *
 * onShow - null or function - Default: null
 * The callback when the toast message shows
 *
 * onClose - null or function - Default: null
 * The callback when the toast message closes
 *
 *
 * Example usage:
 *
 * Toast.create('Link copied');
 *
 * Toast.create('Link copied', 'info', {
 *     timeout: 3000,
 *     interactive: true,
 *     appearDelay: 0,
 *     showCloseButton: true
 * });
 *
 * Toast.info('An info message!');
 * Toast.success('A success message!');
 * Toast.warning('A warning message!');
 * Toast.error('An error message!');
 *
 * <div class="show-toast" data-type="success">A message goes here</div>
 * Toast.create(document.querySelector('.show-toast'));
 *
 * <div class="show-toast" data-type="success" data-message="A message goes here"></div>
 * Toast.create(document.querySelector('.show-toast'));
 *
 *
 * To destroy the current message:
 *
 * var message = Toast.success('A success message!');
 * message.destroy();
 *
 *
 * To destroy all messages:
 *
 * Toast.destroyAll();
 */

class Toast {
    static get _CONSTANTS() {
        return {
            CLASSES: {
                MESSAGE: 'toast-message',
                CONTAINER: 'toast-container',
                CONTENT: 'toast-content',
                CLOSE: 'toast-close',
                VISIBLE: '_visible',
            },
            TYPES: {
                SUCCESS: 'success',
                WARNING: 'warning',
                ERROR: 'error',
                INFO: 'info',
            }
        };
    }

    static get DEFAULT_OPTIONS() {
        return {
            timeout: 3000,
            interactive: true,
            appearDelay: 0,
            showCloseButton: true,
            onShow: null,
            onClose: null,
        };
    }

    constructor (
        message,
        type = Toast._CONSTANTS.TYPES.INFO,
        options = {}
    ) {
        // Remove already existing messages.
        // this._removeExistingMessages();
        this.constructor._removeExistingMessages();

        // Toast message elements
        this.$_message = null;
        this.$_container = null;
        this.$_closeBtn = null;
        this.$_content = null;
        this.$_element = null;

        // Close timeout
        this._c_timeout = null;

        // Get options
        this.setOptions(options);

        // If message is an Element
        if (message instanceof Element) {
            // Get the data from the Element in the DOM
            this.$_element = message;
            this._composeMessage();
        }
        else {
            // Add message from string passed in via JS
            this.message = message;
            this.type = type;
        }

        // Create the message element
        this._createMessage();

        // Create the message
        this._createContent();
    }

    static create(message, type = Toast._CONSTANTS.TYPES.INFO, options = {}) {
        return new Toast(message, type, options);
    }

    static success(message, options = {}) {
        return new Toast(message, Toast._CONSTANTS.TYPES.SUCCESS, options);
    }

    static warning(message, options = {}) {
        return new Toast(message, Toast._CONSTANTS.TYPES.WARNING, options);
    }

    static error(message, options = {}) {
        return new Toast(message, Toast._CONSTANTS.TYPES.ERROR, options);
    }

    static info(message, options = {}) {
        return new Toast(message, Toast._CONSTANTS.TYPES.INFO, options);
    }

    static destroyAll() {
        this._removeExistingMessages();
    }

    static _removeExistingMessages() {
        // Get all the current messages
        let messages = document.querySelectorAll('.' + Toast._CONSTANTS.CLASSES.MESSAGE);

        // Loop through all the messages
        for(let i = 0; i < messages.length; i++) {
            // Remove the message
            messages[i].remove();
        }
    }

    setOptions(options = {}) {
        let filteredOptions = {}; // Filtering the user supplied options to avoid possible prototype pollution vulnerability.

        for(let key in Toast.DEFAULT_OPTIONS) {
            if(key in options) {
                filteredOptions[key] = options[key];
            }
        }

        this.options = Object.assign({}, Toast.DEFAULT_OPTIONS, filteredOptions);
        return this;
    }

    destroy() {
        this._close();
    }

    _composeMessage() {
        // Get toast message from the Element in the DOM

        // Get the message
        this.message = this.$_element.dataset.message || this.$_element.innerHTML || '';
        // Get the type - Default to info
        this.type = this.$_element.dataset.type || Toast._CONSTANTS.TYPES.INFO;
    }

    _createMessage() {
        // Create toast element
        this.$_message = document.createElement('div');
        this.$_message.classList.add(Toast._CONSTANTS.CLASSES.MESSAGE, `toast-${this.type}`);

        // Create container
        this.$_container = document.createElement('div');
        this.$_container.classList.add(Toast._CONSTANTS.CLASSES.CONTAINER);

        // Add container to toast element
        this.$_message.append(this.$_container);

        // Test whether to show close button
        if (this.options.showCloseButton) {
            // Create close button
            this.$_closeBtn = document.createElement('button');

            // Accessibility label
            this.$_closeBtn.setAttribute('aria-label', 'Close');

            // Add classes
            this.$_closeBtn.classList.add(Toast._CONSTANTS.CLASSES.CLOSE);

            // Add SVG to button
            this.$_closeBtn.innerHTML = `<svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z" clip-rule="evenodd" /></svg>`;

            // Add close button to container
            this.$_container.append(this.$_closeBtn);
        }

        // Add the message into DOM
        document.body.append(this.$_message);
    }

    _createContent(){
        this.$_content = document.createElement('div');
        this.$_content.classList.add(Toast._CONSTANTS.CLASSES.CONTENT);
        this.$_content.innerHTML = this.message;

        // Add content to toast message
        this.$_container.prepend(this.$_content);

        // Set behavior
        this._behavior();

        // Test if message in interactive
        if (this._isInteractive() === true) {
            this._bindEvents();
        }
    }

    _behavior() {
        // Set appear delay
        window.setTimeout(() => {
            // This small timeout of is needed for animations
            // Gives time for the element to be added to the DOM and for the transition or animation to take effect
            setTimeout(() => {
                // Add visible class
                this.$_message.classList.add(Toast._CONSTANTS.CLASSES.VISIBLE);

                // Run the toast message
                this._run();

                if (typeof this.options.onShow === 'function') {
                    this.options.onShow();
                }
            }, 20);
        }, this.options.appearDelay);
    }

    _run() {
        if (this.options.timeout > 0) {
            this._c_timeout = window.setTimeout(() => {
                // Close toast message
                this._close();
            }, this.options.timeout);
        }
    }

    _stop() {
        if (this.options.timeout > 0 && this._c_timeout !== null) {
            // Clear timeout
            window.clearTimeout(this._c_timeout);
            // Reset timeout var
            this._c_timeout = null;
        }
    }

    _closeImmediately() {
        // Remove element
        this.$_message.remove();

        // Execute function on close if defined.
        if (typeof this.options.onClose === 'function') {
            this.options.onClose();
        }
    }

    _close() {
        // Test if element is interactive
        if (this._isInteractive()) {
            // Unbind interactions
            this._unbindEvents();
        }

        // If the message element has transition animation, then handle the animation before removing the element from DOM.
        if (this._elementHasTransition(this.$_message)) {
            // Event that gets fired when animation is ended.
            this.$_message.addEventListener('transitionend', () => {
                this._closeImmediately();
            });

            // Remove visible class to make the animation.
            this.$_message.classList.remove(Toast._CONSTANTS.CLASSES.VISIBLE);
        }
        // Otherwise just remove the element.
        else {
            this._closeImmediately();
        }
    }

    _bindEvents() {
        // Bind events to the element
        this._bindEvent('mouseover', this.$_message, _ => this._stop());
        this._bindEvent('mouseleave', this.$_message, _ => this._run());

        // Bind events to the close button
        if (this.options.showCloseButton) {
            this._bindEvent('click', this.$_closeBtn, _ => this._close());
        }
    }

    _bindEvent(event_name, element, callback) {
        try {
            if (!element.addEventListener) {
                element.attachEvent(`on${this._getCapitalizedEventName(event_name)}`, callback);
            }
            else {
                element.addEventListener(event_name, callback, false);
            }
        } catch (err) {
            throw new Error(`Toast._bindEvent - Cannot add event on element - ${err}`);
        }
    }

    _unbindEvents() {
        // Unbind events from close element
        this._unbindEvent('mouseover', this.$_message, _ => this._stop());
        this._unbindEvent('mouseleave', this.$_message, _ => this._run());

        // Unbind events from close button
        if (this.options.showCloseButton) {
            this._unbindEvent('click', this.$_closeBtn, _ => this._close());
        }
    }

    _unbindEvent(event_name, element, callback) {
        try {
            if (!element.removeEventListener) {
                element.detachEvent(`on${this._getCapitalizedEventName(event_name)}`, callback);
            }
            else {
                element.removeEventListener(event_name, callback, false);
            }
        }
        catch(err) {
            throw new Error(`Toast._unbindEvent - Cannot remove event on element - ${err}`);
        }
    }

    _isInteractive() {
        // Return whether the message has the interactive option
        return Boolean(this.options.interactive === true);
    }

    _getCapitalizedEventName (event_name) {
        return event_name.charAt(0).toUpperCase() + event_name.substr(1);
    }

    _elementHasTransition(element) {
        // Get the styles of the element
        let styles = window.getComputedStyle(element);

        // Get the transition duration if there is one and is greater the 0
        return 'transitionDuration' in styles && styles['transitionDuration'] != '0s';
    }
}

export default Toast;
