// Imports
import Alpine from "alpinejs";
import MicroModal from "micromodal";
import { lock, unlock } from 'tua-body-scroll-lock';

class Modal {
    constructor() {
        document.addEventListener('alpine:init', () => {

            // Magic: $modalOpen
            Alpine.magic('modalOpen', el => expression => {
                this._open(expression);
            });

            // Directive: x-modalOpen
            Alpine.directive('modalOpen', (el, { expression }) => {
                this._open(expression);
            });

            // Magic: $modalClose
            Alpine.magic('modalClose', el => expression => {
                this._close(expression);
            });

            // Directive: x-modalClose
            Alpine.directive('modalClose', (el, { expression }) => {
                this._close(expression);
            });

        });
    }
    _open(expression) {
        let element = document.getElementById(expression);

        if (element) {
            const config = {
                openClass: '_open', // Custom class to be applied when modal is open
                openTrigger: 'data-modal-open', // Custom data attribute to open modal
                closeTrigger: 'data-modal-close', // Custom data attribute to close modal
                onShow: modal => {
                    // This is fired when the modal is opening.
                    // The function receives the modal object as the first parameter and the trigger element as second parameter.

                    // Disable smooth scrolling
                    lock(element);
                },
                onClose: modal => {
                    //This is fired when the modal is closing.
                    // The function receives the modal object as the first parameter and the trigger element as second parameter.

                    // Enable smooth scrolling
                    unlock(element);
                }
            };

            MicroModal.show(expression, config);
        }
    }
    _close(expression) {
        MicroModal.close(expression);
    }
}

export default new Modal();



