/* Plugin imports */
import Alpine from "alpinejs";

/* Project imports */
import Toast from '@js/modules/Toast';

class Copy {
    constructor() {
        document.addEventListener('alpine:init', () => {

            // Magic: $copy
            Alpine.magic('copy', el => expression => {
                this._copy(expression);
            });

            // Directive: x-copy
            Alpine.directive('copy', (el, { expression }) => {
                this._copy(expression);
            });
        });
    }

    _copy(expression) {
        navigator.clipboard.writeText(expression).then(() => {
            console.log('Copy');
            Toast.success('Link copied');
        }, (err) => {
            console.error('Async: Could not copy text: ', err);
            Toast.error('Error - The link could not be copied');
        });
    }
}

export default new Copy();
